*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Roboto', sans-serif;
}

// html {
//   // This defines what 1rem is
//   font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

//   @include respond(tab-land) {
//     // width < 1200?
//     font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
//   }

//   @include respond(tab-port) {
//     // width < 900?
//     font-size: 50%; //1 rem = 8px, 8/16 = 50%
//   }

//   @include respond(big-desktop) {
//     font-size: 75%; //1rem = 12, 12/16
//   }
// }

body {
  box-sizing: border-box;
  background: $background-color;
  background: $background-gradient;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  @include respond(tab-port) {
    padding: 0;
  }
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}
