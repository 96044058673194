h1 {
  font-weight: 700;
  margin-bottom: 2rem;
}

h2 {
  font-size: 1.7rem;
}

.done-icon {
  margin-top: 2rem;
  font-size: 2.5rem;
}

.uppercase {
  text-transform: uppercase;
}

// .MuiTypography-body1 {
//   font-size: 0.8rem;
// }

// .MuiTypography-body2 {
//   font-size: 0.7rem;
// }
