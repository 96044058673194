// DRAWER WIDTH
$drawer-width: 260px;

// COLORS
$color-primary: #8b0000;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$background-color: #fef9e7;
$background-gradient: linear-gradient(315deg, #fffdd0 0%, #fff7cb 74%);
$background-danger-gradient: linear-gradient(to right, #f00000, #dc281e);
$card-background-gradient: linear-gradient(to top, #ff5f6d, #ffc371);

$transparent-background-white: rgba(255, 255, 255, 0.24);
$icon-color: #55c57a;

$color-error: red;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
