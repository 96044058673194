.card {
  font-size: 1rem;
  &-header {
    padding: 0.6rem 0.75rem;
  }

  &-body {
    padding: 0.75rem;
    font-size: 0.8rem;
  }
}

.container {
  max-width: 1700px;
  @include respond(phone-lg) {
    max-width: 100%;
  }
}

.item-layout {
  @include respond(phone-lg) {
    flex-direction: column;
    & > .item {
      width: 100%;
    }
  }
}
